import { jsx as _jsx } from "react/jsx-runtime";
import JoditEditor from "jodit-react";
import { useMemo, useRef } from "react";
const MailEditor = ({ content, setFieldValue }) => {
    const ref = useRef(null);
    const config = useMemo(() => ({
        readonly: false,
        height: "calc(100vh - 166px)",
        buttons: [
            "bold",
            "italic",
            "underline",
            "strikethrough",
            "eraser",
            "ul",
            "ol",
            "font",
            "fontsize",
            "paragraph",
            "lineHeight",
            "superscript",
            "subscript",
            "classSpan",
            "file",
            "image",
            "video",
            "spellcheck",
            "speechRecognize",
            "cut",
            "copy",
            "paste",
            "selectall",
            "copyformat",
            "hr",
            "table",
            "link",
            "symbols",
            "indent",
            "outdent",
            "left",
            "brush",
            "undo",
            "redo",
            "find",
            "fullsize",
            "preview",
            "print",
            {
                text: "Personalization Field",
                list: {
                    "Lead Company": "{lead_company}",
                    "E-mail": "{email}",
                    "First Name": "{first_name}",
                    "Last Name": "{last_name}",
                    "Job Title": "{job_title}",
                },
                childTemplate: (_, key, __) => {
                    return `<span>${key}</span>`;
                },
                exec: (editor, _, { control }) => {
                    const key = control.args[1];
                    editor.selection.insertHTML(key);
                },
            },
        ],
        uploader: {
            insertImageAsBase64URI: true,
        },
    }), []);
    return (_jsx(JoditEditor, { ref: ref, value: content, config: config, 
        // NOTE: performance workaround
        onBlur: newContent => setFieldValue("content", newContent), onChange: _ => { } }));
};
export default MailEditor;
