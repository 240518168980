import { createElement as _createElement } from "react";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { CampaignResponseStatuses, } from "@packages/apollo-hooks";
import { DATE_FORMAT } from "@packages/helpers";
import format from "date-fns/format";
import { useMemo } from "react";
import { useSortBy, useTable } from "react-table";
import EmptyState from "../../../../../shared/components/table/empty-state";
import { getResponseBackgroundColor, getResponseColor, } from "../../../../helpers/responses-colors";
import { getStatusOptions } from "../../messages-tab/email-responses/responses-table/responses-table-content";
const CampaignResponsesTableContent = ({ tableData, }) => {
    const columns = useMemo(() => {
        const emailColumns = new Array(tableData.getCampaignOverviewResponses.emailCount)
            .fill(tableData.getCampaignOverviewResponses.emailCount)
            .map((el, i) => ({
            Header: `Email ${i + 1}`,
            accessor: (row) => row.emails,
            Cell: function EmailCell(data) {
                var _a;
                return (_jsx("div", { children: ((_a = data.value[i]) === null || _a === void 0 ? void 0 : _a.sendsAt)
                        ? format(new Date(data.value[i].sendsAt), DATE_FORMAT)
                        : "" }));
            },
        }));
        return [
            { Header: "E-mail address", accessor: "email" },
            { Header: "First name", accessor: "firstName" },
            { Header: "Last name", accessor: "lastName" },
            { Header: "Lead company", accessor: "leadCompany" },
            { Header: "Job title", accessor: "jobTitle" },
            { Header: "Country", accessor: "country" },
            ...emailColumns,
            { Header: "Status", accessor: "status" },
        ];
    }, [tableData.getCampaignOverviewResponses]);
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
        columns,
        data: tableData.getCampaignOverviewResponses.items || [],
    }, useSortBy);
    return (_jsxs(Table, Object.assign({}, getTableProps(), { children: [_jsx(Thead, { children: headerGroups.map((headerGroup, groupIndex) => (_createElement(Tr, Object.assign({}, headerGroup.getHeaderGroupProps(), { key: groupIndex }), headerGroup.headers.map((column, columnIndex) => (_createElement(Th, Object.assign({}, column.getHeaderProps(), { key: columnIndex, position: columnIndex === 0 ||
                        columnIndex === headerGroup.headers.length - 1
                        ? "sticky"
                        : "relative", left: columnIndex === 0 ? "0" : "unset", right: columnIndex === headerGroup.headers.length - 1 ? "0" : "unset", bg: "gray.200", zIndex: columnIndex === 0 ||
                        columnIndex === headerGroup.headers.length - 1
                        ? "1000"
                        : "unset", boxShadow: columnIndex === 0 ||
                        columnIndex === headerGroup.headers.length - 1
                        ? "md"
                        : "none", minWidth: "105px" }), column.render("Header"))))))) }), _jsxs(Tbody, Object.assign({}, getTableBodyProps(), { children: [rows.map((row, rowIndex) => {
                        prepareRow(row);
                        return (_createElement(Tr, Object.assign({}, row.getRowProps(), { key: rowIndex }), row.cells.map((cell, cellIndex) => {
                            var _a;
                            if (cellIndex === row.cells.length - 1) {
                                return (_jsx(Td, Object.assign({ position: "sticky", right: "0", bg: getResponseBackgroundColor(row.original.status
                                        ? row.original.status
                                        : CampaignResponseStatuses.Other), zIndex: "1000", boxShadow: "md", minWidth: "105px" }, { children: cell.value && (_jsx(Box, Object.assign({ w: "124px", color: "gray.800", fontSize: "xs", backgroundColor: getResponseColor(row.original.status
                                            ? row.original.status
                                            : CampaignResponseStatuses.Other), px: "3", py: "1", borderRadius: "base" }, { children: ((_a = getStatusOptions.find(option => option.value === cell.value)) === null || _a === void 0 ? void 0 : _a.label) || "" }))) }), cellIndex));
                            }
                            else {
                                return (_createElement(Td, Object.assign({}, cell.getCellProps(), { key: cellIndex, position: cellIndex === 0 ? "sticky" : "relative", left: cellIndex === 0 ? "0" : "unset", right: cellIndex === row.cells.length - 1 ? "0" : "unset", bg: getResponseBackgroundColor(row.original.status
                                        ? row.original.status
                                        : CampaignResponseStatuses.Other), zIndex: cellIndex === 0 ? "1000" : "unset", boxShadow: cellIndex === 0 ? "md" : "none" }), cell.render("Cell")));
                            }
                        })));
                    }), rows.length === 0 && _jsx(EmptyState, { type: "responses", columnCount: 8 })] }))] })));
};
export default CampaignResponsesTableContent;
