import { createElement as _createElement } from "react";
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, IconButton, Menu, MenuButton, MenuItem, MenuList, Stack, Table, Tbody, Td, Text, Th, Thead, Tooltip, Tr, } from "@chakra-ui/react";
import { CampaignStatuses } from "@packages/apollo-hooks";
import { DATE_FORMAT } from "@packages/helpers";
import { format } from "date-fns";
import { useMemo } from "react";
import { IoEllipsisVerticalOutline, IoPencilSharp } from "react-icons/io5";
import { generatePath } from "react-router";
import { useNavigate } from "react-router-dom";
import { useSortBy, useTable } from "react-table";
import routesPaths from "../../routing/route-paths";
import EmptyState from "../../shared/components/table/empty-state";
import CampaignBadge from "../campaign-badge-new";
import CopyCampaign from "../copy-campaign";
import DeleteCampaignDialog from "./delete-campaign-dialog";
import { ReactComponent as IssuesIcon } from "./issues.svg";
const CampaignsTable = ({ tableData, setPageNumber, }) => {
    const navigate = useNavigate();
    const columns = useMemo(() => [
        {
            Header: "Campaign Name",
            accessor: "name",
            Cell: ({ row: { original } }) => (_jsxs(Stack, Object.assign({ direction: "row", spacing: "2", align: "center" }, { children: [_jsx(Text, { children: original.name }), original.hasIssues && (_jsx(Tooltip, Object.assign({ hasArrow: true, label: "Some emails have not been sent in this campaign. Please revoke access and reintegrate Motar.me with your email account.", bg: "gray.800", color: "white" }, { children: _jsx(IssuesIcon, {}) })))] }))),
        },
        {
            Header: "",
            accessor: "status",
            Cell: ({ row: { original } }) => (_jsx(Box, Object.assign({ textAlign: "right" }, { children: _jsx(CampaignBadge, { status: original.status }) }))),
        },
        {
            Header: "Start date",
            accessor: original => original.startsAt && format(new Date(original.startsAt), DATE_FORMAT),
        },
        {
            Header: "End date",
            accessor: original => original.endsAt && format(new Date(original.endsAt), DATE_FORMAT),
        },
    ], []);
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({ columns, data: tableData }, useSortBy);
    const handleRowClick = (row, cellIndex) => {
        var _a;
        if (!(row.original.status === CampaignStatuses.Deleted) &&
            cellIndex !== row.cells.length) {
            const campaignEmail = (_a = row.original.campaignEmails) === null || _a === void 0 ? void 0 : _a.find(() => true);
            if (campaignEmail) {
                navigate(generatePath(routesPaths.CAMPAIGN_WITH_EMAIL, {
                    id: row.original.id,
                    emailId: campaignEmail.id,
                }));
            }
            else {
                navigate(generatePath(routesPaths.CAMPAIGN, { id: row.original.id }));
            }
        }
    };
    return (_jsxs(Table, Object.assign({}, getTableProps(), { children: [_jsx(Thead, { children: headerGroups.map((headerGroup, groupIndex) => (_createElement(Tr, Object.assign({}, headerGroup.getHeaderGroupProps(), { key: groupIndex }),
                    headerGroup.headers.map((column, columnIndex) => (_createElement(Th, Object.assign({}, column.getHeaderProps(), { key: columnIndex }), column.render("Header")))),
                    _jsx(Th, {})))) }), _jsxs(Tbody, Object.assign({}, getTableBodyProps(), { children: [rows.map((row, rowIndex) => {
                        prepareRow(row);
                        return (_createElement(Tr, Object.assign({}, row.getRowProps(), { key: rowIndex }),
                            row.cells.map((cell, cellIndex) => (_createElement(Td, Object.assign({}, cell.getCellProps(), { key: cellIndex, onClick: () => handleRowClick(row, cellIndex) }), cell.render("Cell")))),
                            _jsx(Td, Object.assign({ textAlign: "right" }, { children: _jsxs(Menu, { children: [_jsx(MenuButton, { as: IconButton, size: "sm", "aria-label": "Options", icon: _jsx(IoEllipsisVerticalOutline, {}), variant: "outline", colorScheme: "gray" }), _jsxs(MenuList, { children: [_jsx(CopyCampaign, { campaignId: row.original.id }), !(row.original.status === CampaignStatuses.Deleted) && (_jsxs(_Fragment, { children: [_jsx(MenuItem, Object.assign({ icon: _jsx(IoPencilSharp, {}), onClick: () => navigate(routesPaths.CAMPAIGN.replace(":id", row.original.id)) }, { children: "Edit" })), _jsx(DeleteCampaignDialog, { campaignId: row.original.id, name: row.original.name, setPageNumber: setPageNumber })] }))] })] }) }))));
                    }), rows.length === 0 && _jsx(EmptyState, { type: "campaigns", columnCount: 5 })] }))] })));
};
export default CampaignsTable;
